import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_API_URL } from 'shared/utils/constants'

export const certificateAdditionAPI = createApi({
    reducerPath: 'certificateAdditionAPI',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
    endpoints: (builder) => ({
        addCertificate: builder.mutation({
            query: (data) => {
                const formData = new FormData()
                data.forEach(el => {
                    formData.append(el.name, el.data)
                });
                // certRank
                // name
                // surname
                // patronymic
                // issueDatetime
                return {
                    url: `certificates/post/add`,
                    method: 'POST',
                    body: formData,
                    formData: true,
                    validateStatus: (response, result) =>
                        response.status === 200 && !result.error
                }
            },
        })
    }),
})
import React from 'react'
import style from './Layout.module.sass'
import { GetCertRankName } from 'shared/utils/getCertRankName'
import { ReactComponent as NewReleasesSvg } from 'assets/images/new_releases.svg'

export const Layout = ({ certificateData }) => {

    if(certificateData) {
        return(
            <div className={style.container}>
                <h1 className={style.title}>
                <NewReleasesSvg/>{`Сертификат #${certificateData.id}`}
                </h1>
                <div className={style.list}>
                    <div className={style.item}>
                        <span>ФИО владельца сертификата</span>
                        <span>{`${certificateData.surname} ${certificateData.name} ${certificateData.patronymic}`}</span>
                    </div>
    
                    <div className={style.item}>
                        <span>Грейд сертификата</span>
                        <span><GetCertRankName rankId={certificateData.certRank}/>*</span>
                    </div>

                    <div className={style.certInfo}>
                        <span>*Профессиональный сертификат означает, что мастер прошёл обучение и&nbsp;получил следующие навыки в&nbsp;соответствии с&nbsp;техническими требованиями</span>
                        <ul>
                            <li><span>Навык</span></li>
                            <li><span>Навык</span></li>
                            <li><span>Навык</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={style.container}>
                <h1 className={style.title}>
                {`Сертификат не найден`}
                </h1>
            </div>
        )
    }
}
import React from 'react'
import { MainForm } from 'shared/forms'
import { InputDate, InputText, Select } from 'shared/fields'
import { MainBtn } from 'shared/buttons'

export const Layout = ({ formik, isRequestPending, certRanks }) => {

// certRank
// name
// surname
// patronymic
// issueDatetime

    return(
        <MainForm onSubmit={formik.handleSubmit}>
            <Select
                label={'Грейд сертификата'}
                disabledText={'Выберите грейд'}
                id={'certRank'}
                name={'certRank'}
                options={certRanks}
                fieldProps={{...formik.getFieldProps('certRank')}}
                formikTouched={formik.touched.certRank}
                formikErrors={formik.errors.certRank}
                disabled={isRequestPending}
                />
            <InputDate
                label={'Дата выдачи'}
                id={'issueDatetime'}
                name={'issueDatetime'}
                formikFieldProps={{...formik.getFieldProps('issueDatetime')}}
                formikTouched={formik.touched.issueDatetime}
                formikErrors={formik.errors.issueDatetime}
                disabled={isRequestPending}
                />
            <InputText
                label={'Фамилия'}
                id={'surname'}
                name={'surname'}
                formikFieldProps={{...formik.getFieldProps('surname')}}
                formikTouched={formik.touched.surname}
                formikErrors={formik.errors.surname}
                disabled={isRequestPending}
                />
            <InputText
                label={'Имя'}
                id={'name'}
                name={'name'}
                formikFieldProps={{...formik.getFieldProps('name')}}
                formikTouched={formik.touched.name}
                formikErrors={formik.errors.name}
                disabled={isRequestPending}
                />
            <InputText
                label={'Отчество'}
                id={'patronymic'}
                name={'patronymic'}
                formikFieldProps={{...formik.getFieldProps('patronymic')}}
                formikTouched={formik.touched.patronymic}
                formikErrors={formik.errors.patronymic}
                disabled={isRequestPending}
                />
                <MainBtn disabled={isRequestPending} type="submit">
                    Добавить сертификат
                </MainBtn>
        </MainForm>
    )
}
import React from "react"
import { ReactComponent as LicenseSvg } from 'assets/images/license.svg'
import style from './Content.module.sass'

export const Content = () => {
    return(
        <div className={style.image}>
            <LicenseSvg/>
        </div>
    )
}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_API_URL } from 'shared/utils/constants'

export const certificatesAPI = createApi({
    reducerPath: 'certificatesAPI',
    tagTypes: ['CertList'],
    baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
    endpoints: (builder) => ({
        getCertificates: builder.query({
            query: () => {
                return {
                    url: `certificates/get/all`,
                    validateStatus: (response, result) =>
                        response.status === 200 && !result.error}
            },
            providesTags: (result) =>
                result.data
                    ? 
                    [
                        ...result.data.map(({ id }) => ({ type: 'CertList', id })),
                        { type: 'CertList', id: 'LIST' },
                    ]
                    :
                    [{ type: 'CertList', id: 'LIST' }],
        }),
    }),
})

export const { 
    useGetCertificatesQuery,
} = certificatesAPI
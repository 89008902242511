import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_API_URL } from 'shared/utils/constants'

export const certificateCardAPI = createApi({
    reducerPath: 'certificateCardAPI',
    tagTypes: ['CertificateCard'],
    baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
    endpoints: (builder) => ({
        getCertificate: builder.query({
            query: ({ id }) => ({
                url: `certificates/get/item/${id}`,
                validateStatus: (response, result) =>
                    response.status === 200 && !result.error
            }),
            providesTags: ['CertificateCard']
        }),
    }),
})

export const {
    useGetCertificateQuery
} = certificateCardAPI
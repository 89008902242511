import { routerConfig } from 'shared/configs'

class MenuConfig {
    menu = [
        {id: 1, path: '/' + routerConfig.panel + '/' + routerConfig.codes, title: 'Гарантийные коды' },
        {id: 2, path: '/' + routerConfig.panel + '/' + routerConfig.certificates, title: 'Сертификаты' }
    ]
}


export const menuConfig = new MenuConfig()
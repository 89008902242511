import React from 'react'
import { Layout } from './Layout'
import { useParams } from 'react-router-dom'
import { useGetCertificateQuery } from 'modules/certificateManager/certificateCard/model'
import { MainPreloader } from 'modules/preloaders'
import style from './Layout.module.sass'

export const Container = () => {

    const { cert } = useParams()

    const {
        data: certificateData, 
        // error: certificateDataError, 
        // isLoading: certificateDataIsLoading, 
        // refetch: refetchCertificateData,
        isFetching: certificateDataIsFetching
    } = useGetCertificateQuery({
        id: cert
    })

    document.title = `Сертификат — #${cert}`

    if(!certificateDataIsFetching && certificateData) {
        return(
            <Layout
                certificateData={certificateData.data}
                />
        )
    } else {
        return(
            <div className={style.preloader}>
                <MainPreloader/>
            </div>
        )
    }
}
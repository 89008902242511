import React, { useContext } from 'react'
import style from './CertificateItem.module.sass'
import { ModalsContext } from 'modules/modals'
import { GetCertRankName } from 'shared/utils/getCertRankName'
import { CertificateCardModule } from 'modules/certificateManager'

export const CertificateItem = ({ item }) => {

    const { mainModalController } = useContext(ModalsContext)
    
    return(
        <div onClick={
                (event) => {
                    if(!event.target.closest('[data-cancel-click]'))
                        mainModalController.mountMainModal(<CertificateCardModule certificateId={item.id}/>, `${item.surname} ${item.name} ${item.patronymic}`)
                }} 
                className={style.item}
                >
            <div className={style.container}>
                <div className={style.code}>
                    {`${item.surname} ${item.name} ${item.patronymic}`}
                </div>
                <div className={style.status}>
                    <GetCertRankName rankId={item.certRank}/>
                </div>
            </div>
        </div>
    )
}
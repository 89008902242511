import React, { useContext } from "react"
import { Layout } from "./Layout"
import { ModalsContext } from "modules/modals/context"

export const Container = ({ children }) => {

    const { menuModalController } = useContext(ModalsContext)

    const closeModal = (e, closedByBtn=false) => {
        if(closedByBtn) {
            menuModalController.unmountMenuModal()
        } else {
            if(!e.target.closest('[data-modal="menu-modal-content"]')) {
                menuModalController.unmountMenuModal()
            }
        }
    }

    return(
        <Layout
            closeModal={closeModal}
            >
            {children}
        </Layout>
    )
}
import React from 'react'
import style from './CertPreloader.module.sass'
import { ReactComponent as LicenseStrongSvg } from 'assets/images/license_strong.svg'
import cn from 'classnames'
import { CERT_STATUSES } from "shared/utils/constants"

export const CertPreloader = ({ status=null }) => {
    return(
        <div className={style.page}>
            <div className={cn(style.container,
                    {[style.found]: status === CERT_STATUSES.FOUND ? true : false},
                    {[style.notFound]: status === CERT_STATUSES.NOTFOUND ? true : false}
                )}>
                <div className={style.icon}>
                    <LicenseStrongSvg/>
                </div>
                <div className={style.preloader}></div>
            </div>
        </div>
    )
}
export const BASE_API_URL = 'https:///api.ppfunion.com/ppfunionapi/'
export const CODE_REGISTER_PAGE_URL = 'https://w.ppfunion.com/register/'
export const IMAGES_DIRECTORY = 'https:///api.ppfunion.com/ppfunionapi/storage/images/'

export const PHOTO_LIMIT_ON_CODE_REGISTER = 10
export const CODE_QUERY_PAGE_LIMIT = 40
export const CODE_STATUS_NAMES = {
    '1': 'status_new',
    '2': 'status_active',
    '3': 'status_registered'
}

export const RANK_CERT_IMAGES = {
    1: "https://api.ppfunion.com/ppfunionapi/storage/certsTemlates/basic-1.jpg",
    2: "https://api.ppfunion.com/ppfunionapi/storage/certsTemlates/deep-2.jpg",
    3: "https://api.ppfunion.com/ppfunionapi/storage/certsTemlates/pro-3.jpg",
}

export const CODE_STATUS_INFO = {
    '1': 'Распечатайте, отправьте на упаковку и активируйте код гарантии, чтобы пользователь смог его зарегистрировать.',
    '2': 'Этот код гарантии ещё никем не зарегистрирован.',
    '3': null
}

export const CERT_STATUSES = {
    FOUND: 'FOUND',
    NOTFOUND: 'NOTFOUND',
}
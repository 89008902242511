import React, { useContext } from 'react'
import style from './Layout.module.sass'
import { ReactComponent as AddCertSvg } from 'assets/images/add.svg'
import { ModalsContext } from 'modules/modals'
import { СertificateAdditionForm } from 'modules/certificateAddition/certificateAdditionForm'


export const Layout = () => {

    const { centeredModalController } = useContext(ModalsContext)

    return(
        <button className={style.btn} type="button"
            onClick={
                () => centeredModalController.mountCenteredModal(<СertificateAdditionForm/>, 'Добавить сертификат')
            }
            >
            <AddCertSvg/>
        </button>
    )
}
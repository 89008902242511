import React, { useContext } from 'react'
import style from './Layout.module.sass'
import { NavLink } from 'react-router-dom'
import { menuConfig } from 'shared/configs'
import { ModalsContext } from 'modules/modals'
import cn from 'classnames'

export const Layout = () => {

    const { menuModalController } = useContext(ModalsContext)

    return(
        <div className={style.menu}>
            {menuConfig.menu.map(item =>
                <NavLink 
                    key={item.id}
                    to={item.path} 
                    className={({ isActive }) => {
                        return cn(
                            style.item,
                            {[style.itemActive]: isActive}
                        )
                    }}
                    onClick={() => menuModalController.unmountMenuModal()}
                    >
                    {item.title}
                </NavLink>
            )}
        </div>
    )
}
import React from "react"
import { Layout } from "./Layout"
import { MainPreloader } from "modules/preloaders"
import style from './Layout.module.sass'
import { useGetCertificatesQuery } from "modules/certificates/model"

export const Container = () => {

    const {
        data: certificatesData, 
        // error: certificatesDataError, 
        isLoading: certificatesDataIsLoading, 
        refetch: refetchCertificatesData,
        isFetching: certificatesDataIsFetching
    } = useGetCertificatesQuery()

    if(!certificatesDataIsLoading && certificatesData) {
        return(
            <Layout
                data={certificatesData.data}
                isFetching={certificatesDataIsFetching}
                />
        )
    } else {
        return(
            <div className={style.preloader}>
                <MainPreloader/>
            </div>
        )
    }
}
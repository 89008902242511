import React from "react"
import { Layout } from "./Layout"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

export const Container = () => {

    const access = useSelector(store => store.app.data.authData.access)

    if(access) {
        return(
            <Layout/>
        )
    } else {
        return <Navigate to={'/access-denied'}/>
    }
}
import React, { useContext }  from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from './Layout'
import { ModalsContext } from 'modules/modals'
import { editCertificate } from '../../model'
import { dateFormatterForInput } from 'shared/utils/dateFormatterForInput'

export const Container = ({ certData }) => {

    const { centeredModalController, mainModalController } = useContext(ModalsContext)

    const dispatch = useDispatch()
    const isRequestPending = useSelector(store => store.certificateEditForm.certEditing.isPending)
    const certRanks = useSelector(store => store.app.data.appData.certRanks)

    const formik = useFormik({
        initialValues: {
            certId: certData.id,
            certRank: certData.certRank,
            name: certData.name,
            surname: certData.surname,
            patronymic: certData.patronymic,
            issueDatetime: dateFormatterForInput(certData.issueDatetime)
        },
        validationSchema: Yup.object({
            certRank: Yup.number()
                .positive("Выберите грейд сертификата")
                .required("Выберите грейд сертификата"),
            name: Yup.string()
                .required("Введите имя мастера"),
            surname: Yup.string()
                .required("Введите фамилию мастера"),
            patronymic: Yup.string()
                .required("Введите отчество мастера"),
            issueDatetime: Yup.date()
                .required("Введите дату выдачи"),
        }),
        onSubmit: async (values) => {
            
            await dispatch(editCertificate([
                {name: 'certId', data: values.certId},
                {name: 'certRank', data: values.certRank},
                {name: 'name', data: values.name},
                {name: 'surname', data: values.surname},
                {name: 'patronymic', data: values.patronymic},
                {name: 'issueDatetime', data: values.issueDatetime},
            ]))
            centeredModalController.unmountCenteredModal()
            mainModalController.unmountMainModal()
        }
    })


    return(
        <Layout
            formik={formik}
            isRequestPending={isRequestPending}
            certRanks={certRanks}
            />
    )
}
import React, { useContext } from "react"
import { Layout } from "./Layout"
import { withAuth } from "shared/hoc"
import { ModalsContext } from "modules/modals"
import { PanelMenu } from "modules/menu"

export const Container = ({ disableSearch=false }) => {

    const { menuModalController } = useContext(ModalsContext)

    const onMenuOpen = () => {
        menuModalController.mountMenuModal(<PanelMenu/>)
    }

    const LayoutWithAuth = withAuth(Layout)

    return <LayoutWithAuth disableSearch={disableSearch} onMenuOpen={onMenuOpen}/>
}
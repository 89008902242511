import React from 'react'
import style from './Fullname.module.sass'
import cn from 'classnames'

export const Fullname = ({ fullname, certRank }) => {
    return(
        <div className={cn(
                    style.fio,
                    {[style.light]: certRank===2 || certRank===3},
                    {[style.dark]: certRank===1 },
                )}>
            {fullname}
        </div>
    )
}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isMounted: false,
}

const menuModalSlice = createSlice({
    name: 'menuModal',
    initialState,
    reducers: {
        setMenuModal(state) {
            state.isMounted = true
        },
        unsetMenuModal(state) {
            state.isMounted = false
        }
    }
})

export const { setMenuModal, unsetMenuModal } = menuModalSlice.actions
export default menuModalSlice.reducer
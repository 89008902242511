import React, { useEffect, useState } from 'react'
import { Layout } from './Layout'
import { useParams } from 'react-router-dom'
import { useGetCertificateQuery } from 'modules/certificateManager/certificateCard/model'
import { CertPreloader } from '../CertPreloader/CertPreloader'
import { CERT_STATUSES } from "shared/utils/constants"

export const Container = () => {

    const { cert } = useParams()
    const [ certStatus, setCertStatus ] = useState(null)
    const [ isLoaded, setIsLoaded ] = useState(false)

    const {
        data: certificateData, 
        // error: certificateDataError,
        // isLoading: certificateDataIsLoading,
        // refetch: refetchCertificateData,
        isFetching: certificateDataIsFetching
    } = useGetCertificateQuery({
        id: cert
    })

    useEffect(() => {
        if(certificateData) {
            if(certificateData.data) {
                setCertStatus(CERT_STATUSES.FOUND)
            } else {
                setCertStatus(CERT_STATUSES.NOTFOUND)
            }
            setTimeout(() => {
                setIsLoaded(true)
            }, 2000)
        }
    }, [certificateData])

    if(!certificateDataIsFetching && certificateData && isLoaded) {
        return(
            <Layout
                certificateData={certificateData.data}
                />
        )
    } else {
        return(
            <CertPreloader
                status={certStatus}
                />
        )
    }
}
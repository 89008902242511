import { Provider } from 'react-redux'
import store from 'rtk/configuration'
import themeStyles from './../layout/Layout.module.sass'
import { useHref } from 'react-router-dom'

export const ThemeProvider = ({ children }) => {
    
    const themeData = {
        theme: 'mainTheme'
    }
    
    const brandThemeRoutes = [
        'register',
        'certificate-checker'
    ]

    const href = useHref()
    const splittedHref = href.split('/')
    if(brandThemeRoutes.includes(splittedHref[1]) || brandThemeRoutes.includes(splittedHref[2])) themeData.theme = 'brandTheme'

    return(
        <Provider store={store}>
            <div className={themeStyles[themeData.theme]}>   
                {children}
            </div>
        </Provider>
    )
}
import React from "react"
import style from './Layout.module.sass'
import { CertificatesListModule } from "modules/certificates/certificatesList"
import { CertificateAdditionBtn } from "modules/certificateAddition"

export const Layout = () => {
    return(
        <div className={style.panel}>
            <CertificatesListModule/>
            <CertificateAdditionBtn/>
        </div>
    )
}
import React, { useCallback, useContext } from 'react'
import { Layout } from './Layout'
import { useGetCertificateQuery } from '../../model'
import { MainPreloader } from 'modules/preloaders'
import style from './Layout.module.sass'
import { ModalsContext } from 'modules/modals'
import { СertificateEditForm } from 'modules/certificateManager/certificateEditForm'

export const Container = ({ certificateId }) => {

    const { centeredModalController } = useContext(ModalsContext)

    const {
        data: certificateData, 
        // error: certificateDataError, 
        // isLoading: certificateDataIsLoading, 
        // refetch: refetchCertificateData,
        isFetching: certificateDataIsFetching
    } = useGetCertificateQuery({
        id: certificateId
    })

    const onEditCertModalOpen = useCallback(() => {
        centeredModalController.mountCenteredModal(
            <СertificateEditForm certData={certificateData.data}/>, 
            `Изменить сертификат #${certificateData?.data.id}`
        )
    }, [certificateData, centeredModalController])

    if(!certificateDataIsFetching && certificateData) {
        return(
            <Layout
                certificateData={certificateData.data}
                onEditCertModalOpen={onEditCertModalOpen}
                />
        )
    } else {
        return(
            <div className={style.preloader}>
                <MainPreloader/>
            </div>
        )
    }
}
import React from "react"
import commonStyle from 'assets/styles/common.module.sass'
import { PanelHeaderModule } from "modules/header"
import cn from "classnames"
import { CertificatesModule } from "modules/certificates"

export const Certs = () => {

    return(
        <>
            <header className={cn(commonStyle.header, commonStyle.header_panel)}>
                <div className={commonStyle.wrap}>
                    <div className={commonStyle.container}>
                        <PanelHeaderModule disableSearch={true}/>
                    </div>
                </div>
            </header>
            <main className={commonStyle.main}>
                <div className={commonStyle.wrap}>
                    <div className={commonStyle.container}>
                        <CertificatesModule/>
                    </div>
                </div>
            </main>
        </>
    )
}
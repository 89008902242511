import React from "react"
import { AppProvider, ThemeProvider } from "app/providers"
import { ModalsModule, ModalsProvider } from "modules/modals"
import { AppRouter } from "app/routers"
import { AppInitialization } from "app/initialization"
import { MicroAlert } from "modules/alerts"
import { BrowserRouter } from "react-router-dom"
const App = () => {
  
  return (
    <BrowserRouter>
      <AppProvider>
        <ThemeProvider>
          <ModalsProvider>
            <AppInitialization>
              <AppRouter/>
            </AppInitialization>
            <ModalsModule/>
            <MicroAlert/>
          </ModalsProvider>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  )
}

export default App
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setNewMicroalert } from 'modules/alerts'
import { certificateAdditionAPI } from './certificateAdditionAPI'
import { certificatesAPI } from 'modules/certificates'

export const addNewCertificate = createAsyncThunk(
    'certificateAddition/addNewCertificate',
    async (data, {dispatch, fulfillWithValue, rejectWithValue}) => {
        try {
            
            const response = await dispatch(certificateAdditionAPI.endpoints.addCertificate.initiate(data))
            // response.error приходит если на сервере ошибка 500 или другая
            if(response.data.error) {
                throw new Error('Error')
            } else {
                dispatch(setNewMicroalert({text: 'Сертификат успешно добавлен'}))
                dispatch(certificatesAPI.util.invalidateTags(["CertList"]))
                fulfillWithValue(true)
            }
        } catch (error) {
            console.error(error)
            dispatch(setNewMicroalert({text: 'Ошибка при добавлении сертификата'}))
            rejectWithValue(true)
        }
    } 
)


const initialState = {
    certAdding: {
        isPending: false,
    }
}

const certificateAdditionSlice = createSlice({
    name: 'certificateAddition',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewCertificate.pending, (state) => {
                state.certAdding.isPending = true
            })
            .addCase(addNewCertificate.fulfilled, (state) => {
                state.certAdding.isPending = false
            })
            .addCase(addNewCertificate.rejected, (state) => {
                state.certAdding.isPending = false
            })
    }
})

export default certificateAdditionSlice.reducer
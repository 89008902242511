import React from 'react'
import style from './Layout.module.sass'
import { SecondBtn } from 'shared/buttons'
import printSvg from 'assets/images/print.svg'
import { Content } from '../Content/Content'
import { GetCertRankName } from 'shared/utils/getCertRankName'
import { routerConfig } from 'shared/configs'
import { dateFormatter } from 'shared/dateFormatter'

export const Layout = ({ certificateData, onEditCertModalOpen }) => {

    return(
        <div className={style.container}>
            <div className={style.status}>
            {dateFormatter(certificateData.issueDatetime)} — <GetCertRankName rankId={certificateData.certRank}/>
            </div>
            <Content/>
            <div className={style.btns}>
                <SecondBtn 
                    icon={printSvg}
                    to={`/${routerConfig.panel}/${routerConfig.cert}/${certificateData.id}`}
                    newTab={true}
                    >
                    Распечатать
                </SecondBtn>
                <SecondBtn 
                    type={'button'}
                    onClick={() => onEditCertModalOpen()}
                    >
                    Изменить сертификат
                </SecondBtn>
            </div>
        </div>
    )
}
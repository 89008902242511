import React from "react"
import style from './Layout.module.sass'
import { MainBtn } from "shared/buttons"
import { QRCodeCanvas } from "qrcode.react"
import cn from "classnames"
import { RANK_CERT_IMAGES } from "shared/utils/constants"
import { routerConfig } from "shared/configs"
import { GetCertRankName } from "shared/utils/getCertRankName"
import { Fullname } from "../Fullname/Fullname"

export const Layout = ({ certificateData }) => {
    
    return(
        <div className={style.wrapper}>
            <div className={style.certFrame}>
                
                <img src={RANK_CERT_IMAGES[certificateData.certRank]} alt=""/>
                <div className={cn(style.qrCode, style.qrCodePrint)}>
                    <QRCodeCanvas
                        value={`https://w.ppfunion.com/${routerConfig.certficateChecker}/${certificateData.id}`}
                        size={111}
                        />
                </div>
                <Fullname
                    certRank={certificateData.certRank}
                    fullname={`${certificateData.surname} ${certificateData.name} ${certificateData.patronymic}`}
                />
                
            </div>
            <div className={style.cert}>
                ID — {certificateData.id}
            </div>
            <div className={style.certInfo}>
                <div className={style.certInfo__item}>
                    {`Мастер: ${certificateData.surname} ${certificateData.name} ${certificateData.patronymic}`}
                </div>
                <div className={style.certInfo__item}>
                    Грейд сертификата: <GetCertRankName rankId={certificateData.certRank}/>
                </div>
            </div>
            <MainBtn onClick={() => window.print()}>
                Перейти к печати
            </MainBtn>
        </div>

        
    )
}
import React from "react"
import style from './Layout.module.sass'
import { Outlet } from "react-router-dom"

export const Layout = () => {
    return(
        <div className={style.app}>
            <Outlet/>
        </div>
    )
}